<template>
  <div class="demo">
    <div
      id="checkInTime"
      class="chartBar"
      :class="{ echartNoData: !hasFlag &&!showCharts }"
    ></div>
  </div>
</template>
<script>

import CountTo from "vue-count-to";
import { get_checkInOutTime } from "@/api";
import {
  title_Font,
  title2_Font,
  x_Font,
  y_Font,
  w_Font,
  h_Font,
  line_Font,
  lineSize,
  symbolSize_Font,
  symbolSize,
} from "./font";

export default {
  props: {
    roleType: {
      type: String,
      default: "0",
    },
  },
  components: {
    CountTo,
  },
  data() {
    return {
      hasFlag: true,
      showCharts:false,
      lineData: {
        checkInValue: [
          // 333, 223, 233, 546, 254, 456, 865
        ],
        checkOutValue: [
          // 111, 34, 567, 123, 334, 62, 41
        ],
        day: [
          // 1, 2, 3, 4, 5, 6, 7
        ],
      },
    };
  },
  mounted() {
    this.checkInTime = this.$echarts.init(
      document.getElementById("checkInTime")
    );
    window.addEventListener("resize", () => {
      this.checkInTime.resize();
    });
    this.getChinkInType();
    this.lineTimeInter = null;
    this.lineTimeInter = setInterval(() => {
      this.getChinkInType();
    }, 3600000);
  },

  beforeDestroy() {
    clearInterval(this.lineTimeInter);
  },
  methods: {
    getOptions(data) {
      return {
        title: {
          text: "入住时间分布",
          textStyle: {
            align: "left", //字体居中
            color: "#fff", //字体颜色
            fontSize: title2_Font(), //字体大小
          },
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "5%",
          right: "8%",
          bottom: "10%",
          top: title2_Font() * 3,
          containLabel: true,
        },
        legend: {
          // data: [`客房数`, `分店数`],
          bottom: 0,
          itemWidth: w_Font(),
          itemHeight: h_Font(),
          textStyle: {
            color: "#fff",
            fontStyle: "normal",
            fontFamily: "微软雅黑",
            fontSize: x_Font(),
          },
        },
        xAxis: {
          splitLine: {
            show: false,
          },
          data: data.day, //['1','2','3','4','5','6' ],
          axisLabel: {
            color: "#fff",
            fontSize: x_Font(),
          },
        },
        yAxis: {
          type: "value",
          scale: true,
          splitLine: {
            lineStyle: {
              type: "dashed",
              width: lineSize(),
            },
          },

          axisLabel: {
            fontSize: x_Font(),
            color: "#fff",
          },
        },
        series: [
          {
            name: "入住",
            type: "line",
            data: data.checkInValue,
            symbolSize: symbolSize_Font(),
            lineStyle: {
              width: line_Font(), //设置线条粗细
            },
            itemStyle: {
              color: "#00CC00",
              fontSize: x_Font(),
            },
            markPoint: {
              symbolSize: symbolSize(),
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    fontSize: x_Font(),
                  },
                },
              },
              data: [
                {
                  type: "max",
                  name: "最大值",
                },
                { type: "min", name: "最小值" },
              ],
            },
          },
          {
            name: "退房",
            type: "line",
            symbolSize: symbolSize_Font(),
            data: data.checkOutValue,
            lineStyle: {
              width: line_Font(), //设置线条粗细
            },
            itemStyle: {
              color: "#409eff",
              fontSize: x_Font(),
            },
            markPoint: {
              symbolSize: symbolSize(),
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    fontSize: x_Font(),
                  },
                },
              },
              data: [
                {
                  type: "max",
                  name: "最大值",
                },
                { type: "min", name: "最小值" },
              ],
            },
          },
        ],
      };
    },

    async getChinkInType(data = { roleType: this.roleType }) {
      try {
        this.hasFlag = true;
        let res = await get_checkInOutTime(data);
        if (res.data.code == "000000") {
          if (!res.data.data || res.data.data.length == 0) {
            // this.hasFlag = false;
          } else {
            this.showCharts=true
            this.lineData.checkInValue = [];
            this.lineData.checkOutValue = [];
            this.lineData.day = [];
            res.data.data.forEach((item) => {
              this.lineData.checkInValue.unshift(item.checkInValue*this.$store.state.times);
              this.lineData.checkOutValue.unshift(item.checkOutValue*this.$store.state.times);
              this.lineData.day.unshift(item.hour + "时");
            });
          }
        }
      } catch (error) {
        this.hasFlag = false;
      }
      if (this.hasFlag&&this.showCharts) {
        this.initLine();
      }
    },
    initLine() {
      this.checkInTime.setOption(this.getOptions(this.lineData));
    },
    resize() {
      this.checkInTime.resize();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
.demo {
  width: 100%;
  height: 100%;
}
</style>