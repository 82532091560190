var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "demo" }, [
    _c("div", {
      staticClass: "chartBar",
      class: { echartNoData: !_vm.hasFlag && !_vm.showCharts },
      attrs: { id: "checkInTime" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }